/*
Template Name: Chatvia - Responsive Bootstrap 4 Chat App
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";
@import "./bootstrap.scss";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/accordion";
@import "custom/components/forms";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/carousel";

// structure
@import "custom/structure/sidemenu";

// Pages
@import "custom/pages/chat-leftsidebar";
@import "custom/pages/user-chat";
@import "custom/pages/user-profile-details";
@import "custom/pages/authentication";

@import "./icons.scss";

// rtl
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";