/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

body {
    overflow-y: hidden;
}
.custom-bg-blue {
    background-color: #0494db !important;
}
.custom-button-blue {
    background-color: #0494db !important;
    border-color: #0494db !important;
}

.custom-max-width-50 {
    max-width: 50%;
}

.c-pointer{
    cursor: pointer;
}

.btn-close{
    &.custom-srch-offcanvas-close {
        border-radius: 50%;
        padding: 0.5em;
        margin-left: 5%;
        border: 2px solid #0494db;
        color: #0494db;
        opacity: 1 !important;
        position: relative;
        cursor: pointer;
    }
}

.chat-welcome-section {
    display: flex;
    width: 100%;
    // height: 100vh;
    max-height: 100vh;
    min-height: 94vh;
    align-items: center;
    justify-content: center;
    position: relative;
}

.custom-tooltip-container {
    text-align: center;
    z-index: 100;
    position: fixed;
    padding: 6px 12px;
    font-size: 0.66rem;
    font-weight: 600;
    line-height: initial;
    color: white;
    width: auto;
    background: #111111ee;
    box-sizing: border-box;
    opacity: 0;
    transform: translate(-50%, -30%);
    animation: tooltip-slide 0.18s ease-out 0.5s;
    animation-fill-mode: forwards;
    pointer-events: none;
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}


.custom_loader_bg {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    display: none;
}

.custom_loader {
    width: 150px;
    height: 150px;
    display: inline-block;
    padding: 0px;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    span {
        position: absolute;
        display: inline-block;
        width: 150px;
        height: 150px;
        border-radius: 100%;
        background: #26a2e0;
        -webkit-animation: loader3 1.5s linear infinite;
        animation: loader3 1.5s linear infinite;
        &:last-child {
            animation-delay: -0.9s;
            -webkit-animation-delay: -0.9s;
        }
    }
}

@keyframes loader3 {
    0% {
        transform: scale(0, 0);
        opacity: 0.8;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@-webkit-keyframes loader3 {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.8;
    }

    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
    }
}